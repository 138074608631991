<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from "vue-dataset/dist/es/DatasetPager.js";
import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
import Swal from "sweetalert2";
const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Transport du Personnels",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Dataset,
    DatasetItem,
    DatasetPager,
    DatasetShow,
    // DatasetSearch
  },
  data() {
    return {
      title: "Transport du Personnels",
      entries:[],
      data: [],
      arrivals:[],
      departures:[],
      archives:[],
      receives:[],
      dateRange:{},
      selectedIndex:-1,
      selectedRow:{},
      selectedArrival:false,
      selectedDeparture:false,
      selectedArchive:false,
      selectedReceive:false,
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
      items: [
        {
          text: "Catalogue des Services",
          to: {name:"service_catalog"},
        },
        {
          text: "Transport Personnel",
          active: true,
        },
      ],
      nows:now,
      cols: [
        {
          name: "ID",
          field: "id",
          sort: "",
        },
        {
          name: "Structure d'accueil",
          field: "fullName",
          sort: "",
        },
        {
          name: "Nombre de Personnes",
          field: "identitydoc_no",
          sort: "",
        },
        {
          name: "Arrivée/Depart",
          field: "identity_no",
          sort: "",
        },
        {
          name: "Date éxècution",
          field: "type",
          sort: "",
        },
        {
          name: "Heure Exécution",
          field: "amnt",
          sort: "",
        },
        {
          name: "Accusé Réception",
          field: "ddbv",
          sort: "",
        },
        {
          name: "Confirmer l'exécution",
          field: "dexp",
          sort: "",
        },
      ],
    };
  },

  methods: {
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRow = []; this.selectedIndex = -1}
      else {
        this.entries = row.entries
        console.log(row.entries);
        this.selectedRow = row;
        this.selectedIndex = index;
      }
    },
    arrivalsData(){
      this.data = this.arrivals;
      this.selectedArrival = true;
      this.selectedDeparture = false;
      this.selectedArchive = false;
      this.selectedReceive = false;
    },
    departuresData(){
      this.data = this.departures;
      this.selectedDeparture = true;
      this.selectedArrival = false;
      this.selectedArchive = false;
      this.selectedReceive = false;
    },
    registerEntry(uid){
      Swal.fire({
        title: "Êtes-vous sûr de Confirmer la réception ?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Confirmer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/ops/personels_transport/received", {
              uuid: uid,
            })
            .then((res) => {
              var status = res.data;
              this.receives=[];
              this.$toast.success("Accusé de Réception envoyée");
              this.fetchIspLogs();
              this.receivedList();
              loader.hide();
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    registerExit(uid){
      Swal.fire({
        title: "Êtes-vous sûr de Confirmer l'exécution ?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Confirmer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/ops/personels_transport/executed", {
              uuid: uid,
            })
            .then((res) => {
              var status = res.data;
              this.archives=[];
              this.$toast.success("Transport Exécutée");
              this.fetchIspLogs();
              this.archivedList();
              loader.hide();
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    fetchIspLogs(){
      this.$http
        .post("/ops/personels_transport/list")
        .then((res) => {
          this.data = res.data;
          this.arrivals=res.data.filter(element => (element.type_act == 'arrival'));
          this.departures=res.data.filter(element => (element.type_act == 'departure'));
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    searchTP(){
      // console.log(this.dateRange);
      this.$http.post('/ops/personels_transport/search',{start:this.dateRange.start,end:this.dateRange.end})
      .then(response => {
        this.archives = response.data.filter(x => (x.done == 1));
        this.receives = response.data.filter(x => (x.read_at == null));
        this.arrivals = response.data.filter(element => (element.type_act == 'arrival'));
        this.departures = response.data.filter(element => (element.type_act == 'departure'));
        this.data = response.data;
      })
    },
    archivedList(){
      if(this.archives.length == 0){
        this.$http
        .post("/ops/personels_transport/listArchives")
        .then((res) => {
          this.data = res.data;
          this.archives = res.data;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
      }
      else {this.selectedArchive = true;
        this.data = this.archives;}
    },
    receivedList(){
      if(this.receives.length == 0){
        this.$http
        .post("/ops/personels_transport/listReceives")
        .then((res) => {
          this.data = res.data;
          this.receives = res.data;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
      }
      else {this.selectedReceive = true;
        this.data = this.receives;}
    }
  },

  mounted() {
    this.fetchIspLogs();
    this.archivedList();
    this.receivedList();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="row d-flex justify-content-between align-items-center">
                <div class="col-md-6 mb-3">
                  <VueCtkDateTimePicker
                    locale="fr"
                    v-model="dateRange"
                    style="display:inline-block;"
                    :range="true"
                    formatted="ll"
                    color="#34495e"
                    :no-label="true"
                    :custom-shortcuts="customDateRangeShortcuts"
                    :only-date="true"
                    :auto-close="true"
                    :no-header="true"
                  ></VueCtkDateTimePicker>
                </div>
                <div class="col-md-2 mb-3">
                  <button class="btn btn-info" @click="searchTP">Recherche</button>
                </div>
                <div class="col-md-4 mb-3 d-flex justify-content-end">
                  <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        :class="{'active':selectedReceive}"
                        @click="receivedList()"
                      >
                        <i class="fas fa-envelope"></i> <span class="badge bg-danger text-white">{{ receives.length }}</span> Reçu
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        :class="{'active':selectedArrival}"
                        @click="arrivalsData()"
                      >
                        <i class="fas fa-plane-arrival"></i> <span class="badge bg-success text-white">{{ arrivals.length }}</span> Arrivé
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        :class="{'active':selectedDeparture}"
                        @click="departuresData()"
                      >
                        <i class="fas fa-plane-departure"></i>
                        <span class="badge bg-warning text-white ml-1"> {{ departures.length }}</span> Départ
                      </button>
                      
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        :class="{'active':selectedArchive}"
                        @click="archivedList()"
                      >
                        <i class="fas fa-archive"></i> <span class="badge bg-info text-white">{{ archives.length }}</span> Archives
                      </button>
                  </div>
                </div>
              </div>
        <b-card no-body class="mb-1">
          <b-card-body class="shadow-lg">
            <dataset v-slot="{ ds }" :ds-data="data">
              <div class="row">
                <div class="col-md-12">
                  <div class="">
                    <table class="table table-hover d-md-table">
                      <thead>
                        <tr>
                          <th
                            v-for="th in cols"
                            :style="th.field == 'objet' ? 'width: 20%;' : ''"
                            :key="th.field"
                            class="text-center"
                          >
                            {{ th.name }}
                          </th>
                        </tr>
                      </thead>
                      <dataset-item tag="tbody">
                        <template #default="{ row, rowIndex }">
                          <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                            <td class="text-center">{{ rowIndex + 1 }}</td>
                            <td class="text-center">{{ row.structure.name }}</td>
                            <td class="text-center">{{ row.total_visitors }}</td>
                            <td class="text-center">
                              <span v-if="row.type_act =='arrival'" class="badge badge-success">{{ row.type_action }}</span>
                              <span v-else class="badge badge-warning">{{ row.type_action }}</span>
                            </td>
                            <td class="text-center">{{row.exec_date_format}}</td>
                            <td class="text-center">{{row.exec_time}}</td>
                            <td class="text-center"> <button v-if="!row.read_at && $can('personal_transp_log_approve_entry')" class="btn btn-success" @click="registerEntry(row.uuid)">Accusé Réception</button> <span v-else> {{row.read_at_format}} </span> </td>
                            <td class="text-center"> 
                              <button v-if="row.read_at && !row.done && $can('personal_transp_log_approve_exit')" :disabled="row.exec_date != nows" class="btn btn-warning" @click="registerExit(row.uuid)">Confirmer l'exécution</button> 
                              <span v-else>{{row.done_at_format}}</span>
                            </td>
                          </tr>
                        </template>
                      </dataset-item>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-md-row flex-column justify-content-between align-items-center"
              >
                <!-- <dataset-info class="mb-2 mb-md-0" /> -->

                <dataset-show :ds-show-entries="5" />
                <dataset-pager />
              </div>
            </dataset>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <b-card title="Information Générales">
          <b-card-body>
            <table class="table" style="width:100%">
              <tr>
                <td> <h5>Structure d'accueil: </h5> </td>
                <td class="text-right">{{selectedRow.id ? selectedRow.visitor_support.structure.name : 'N/A'}}</td>
              </tr>
              
              <tr>
                <td> <h5>Structure Bénéficiere:</h5>  </td>
                <td class="text-right">{{selectedRow.id ? selectedRow.visitor_support.company_name:'N/A'}}</td>
              </tr>
              
              <tr>
                <td> <h5>Arrivé/Départ:</h5>  </td>
                <td class="text-right">{{selectedRow.id ? selectedRow.type_action : 'N/A'}}</td>
              </tr>
              
              <tr>
                <td> <h5>Nombres de Personnes:</h5> </td>
                <td class="text-right">{{selectedRow.id ? selectedRow.total_visitors : 'N/A'}}</td>
              </tr>
            </table>
            
          </b-card-body>
          <!-- <b-card-text>Header and footers using slots.</b-card-text> -->
        </b-card>
      </div>
      <div v-if="selectedRow.entries" class="col">
        <b-card title="Liste des Personnes">
          <b-card-body>
            <table class="table" style="width:100%">
              <tr v-for="(entry, i) in selectedRow.entries" :key="i">
                <td> <h6>Nom et Prénom: </h6> </td>
                <td class="text-right">{{entry.full_name}}</td>
              </tr>
            </table>
            
          </b-card-body>
          <!-- <b-card-text>Header and footers using slots.</b-card-text> -->
        </b-card>
      </div>
    </div>
  </Layout>
</template>
